import rainbowColorsMap from './rainbowColorsMap';
import FancyButton from './FancyButton';

let wsUrl = `wss://${window.location.host}/websocket`;
window.location.host.split(':')[0] === 'localhost' && (wsUrl = `ws://localhost:8082`);

const ws = new WebSocket(wsUrl);

function App() {
  ws.onmessage = async function (event) {
    const newBgColor = await event.data.text()
    document.documentElement.style.setProperty("--main-color", rainbowColorsMap[newBgColor][200].color);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className=" flex justify-center items-center flex-col">
        <h2 className="mb-4">Press a color 🌈</h2>
        <div className="flex justify-center items-stretch flex-col">
          {Object.keys(rainbowColorsMap).map((bgColor) => <FancyButton key={bgColor} color={bgColor} webSocket={ws} />)}
        </div>
      </div>
    </div>
  );
}

export default App;
