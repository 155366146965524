import React from 'react'


export default function FancyButton({ color, webSocket }) {

  return (
    <button
      type="button"
      onClick={() => webSocket.send(color)}
      className={`focus:outline-none text-white ${`bg-${color}-${400}`} font-medium rounded-lg text-sm px-5 py-2.5 mb-2`}
    >{color}</button>
  )
}
