const rainbowColorsMap = {
    red: {
        200: {
            className: "bg-red-200",
            color: "rgb(255, 178, 178)",
        },
        400: {
            className: "bg-red-400",
            color: "rgb(248, 113, 113)",
        },
        600: {
            className: "bg-red-600",
            color: "rgb(198, 48, 48)",
        },
    },
    orange: {
        200: {
            className: "bg-orange-200",
            color: "rgb(255, 209, 158)",
        },
        400: {
            className: "bg-orange-400",
            color: "rgb(255, 147, 0)",
        },
        600: {
            className: "bg-orange-600",
            color: "rgb(204, 85, 0)",
        },
    },
    yellow: {
        200: {
            className: "bg-yellow-200",
            color: "rgb(255, 253, 152)",
        },
        400: {
            className: "bg-yellow-400",
            color: "rgb(255, 230, 0)",
        },
        600: {
            className: "bg-yellow-600",
            color: "rgb(178, 135, 0)",
        },
    },
    green: {
        200: {
            className: "bg-green-200",
            color: "rgb(167, 243, 208)",
        },
        400: {
            className: "bg-green-400",
            color: "rgb(16, 185, 129)",
        },
        600: {
            className: "bg-green-600",
            color: "rgb(0, 128, 96)",
        },
    },
    blue: {
        200: {
            className: "bg-blue-200",
            color: "rgb(179, 229, 252)",
        },
        400: {
            className: "bg-blue-400",
            color: "rgb(29, 161, 242)",
        },
        600: {
            className: "bg-blue-600",
            color: "rgb(13, 71, 161)",
        },
    },
    indigo: {
        200: {
            className: "bg-indigo-200",
            color: "rgb(196, 181, 253)",
        },
        400: {
            className: "bg-indigo-400",
            color: "rgb(92, 78, 204)",
        },
        600: {
            className: "bg-indigo-600",
            color: "rgb(57, 43, 128)",
        },
    },
    violet: {
        200: {
            className: "bg-purple-200",
            color: "rgb(223, 197, 255)",
        },
        400: {
            className: "bg-purple-400",
            color: "rgb(155, 64, 239)",
        },
        600: {
            className: "bg-purple-600",
            color: "rgb(84, 26, 139)",
        },
    },
};


export default rainbowColorsMap
